<template>
  <div>
    <div class="list">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <div class="total" :class="{'isOld36': isOld == 1}">共{{ totalCount }}条问题</div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                  v-if="dataList.length > 0" offset="10">
          <div class="items" v-for="item in dataList" :key="item.id" @click="goInfo(item)">
            <div class="top">
              <div class="top-left">
                <div class="people" :class="{'isOld34': isOld == 1}">{{ item.createUserName }}</div>
                <div class="time" :class="{'isOld28': isOld == 1}">{{ item.createTime }}</div>
              </div>
              <div class="top-right wufa" :class="{'isOld28': isOld == 1}" v-if="item.solveStatus == 0">
                {{ item.solveStatusStr }}
              </div>
              <div class="top-right" :class="{'isOld28': isOld == 1}" v-if="item.solveStatus == 10">
                {{ item.solveStatusStr }}
              </div>
              <div class="top-right chulizhong" :class="{'isOld28': isOld == 1}"
                   v-if="item.solveStatus == 20 || item.solveStatus == 50">{{ item.solveStatusStr }}
              </div>
              <div class="top-right yichuli" :class="{'isOld28': isOld == 1}" v-if="item.solveStatus == 30">
                {{ item.solveStatusStr }}
              </div>
              <div class="top-right yipinlun" :class="{'isOld28': isOld == 1}" v-if="item.solveStatus == 40">
                {{ item.solveStatusStr }}
              </div>
            </div>
            <div class="bottom">
              <div class="bottom-left">
                <van-image class="picture" :src="item.fileUrl || require('@/assets/img/shanshui.png')" alt="">
                  <template slot="error">
                    <img :src="require('@/assets/img/shanshui.png')" alt="" class="picture">
                  </template>
                  <template slot="loading">
                    <img :src="require('@/assets/img/shanshui.png')" alt="" class="picture">
                  </template>
                </van-image>
                <!-- <img class="picture" :src="require('@/assets/img/shanshui.png')" alt="" /> -->
                <div class="detail">
                  <div class="detail-title" :class="{'isOld34': isOld == 1}">
                    {{ item.houseFullName ? (item.subareaStr + item.houseFullName) : '公共区域' }}
                    <span style="color:#387FF5;font-size:14px">({{ item.problemTypeStr }})</span>
                  </div>
                  <div class="detail-des" v-if="isOld == 1" :class="{'isOld34': isOld == 1}">
                    {{ item.problemDes ? item.problemDes.length > 25 ? item.problemDes.slice(0, 25) + '...' : item.problemDes : '' }}
                  </div>
                  <div class="detail-des" v-else>
                    {{ item.problemDes ? item.problemDes.length > 40 ? item.problemDes.slice(0, 40) + '...' : item.problemDes : '' }}
                  </div>
                </div>
              </div>
              <img class="bottom-right" :src="require('@/assets/img/right.png')" alt="">
            </div>
            <div class="bottom-btn" v-if="(item.solveStatus == 10 || item.solveStatus == 20) && item.solverTypeNow == 1">
<!--              <button-->
<!--                  class="item_btn"-->
<!--                  style="background-color: rgba(78,124,245,0.12); color: #4E7CF5;"-->
<!--                  v-if="item.solveStatus == 10 && createUserId == item.createUserId"-->
<!--                  @click.stop="change(item.id,item.solveStatus, item.streetIsOpen)">编辑-->
<!--              </button>-->
              <button
                  class="item_btn"
                  style="background-color: rgba(255, 169, 58, .1); color: #FFA93A;"
                  @click.stop="handling(item.id,item.solveStatus, item)">处理
              </button>
<!--              <button-->
<!--                  v-if="createUserId == item.createUserId"-->
<!--                  class="item_btn"-->
<!--                  style="background-color: rgba(237, 71, 71, .1); color: #ED4747;"-->
<!--                  @click.stop="deleteHandle(item.id)">删除-->
<!--              </button>-->
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {getImageStream} from '@/utils'

export default {
  data() {
    return {
      isOld: 0,
      page: 0,
      limit: 10,
      totalCount: 0,
      dataList: [],
      finished: false,
      loading: false,
      pullLoading: false,
      dataForm: {
        orgId: '',
        mobile: '',
        placeType: '',
        buildingId: '',
        houseId: ''
      }
    }
  },
  created() {
    if (this.$route.query.domain && this.$route.query.domain != 'null'){
      this.$http.changeUrl(this.$route.query.domain, true)
      this.$httpApp.changeUrl(this.$route.query.domain, true)
      this.$httpCustApp.changeUrl(this.$route.query.domain, true)
    }
    this.dataForm.orgId = this.$route.query.orgId
    this.dataForm.mobile = this.$route.query.mobile
    this.dataForm.placeType = this.$route.query.placeType
    this.dataForm.buildingId = this.$route.query.placeId || this.$route.query.buildingId
    this.dataForm.houseId = this.$route.query.houseId
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/problem/new/list/zzzg'),
        method: 'GET',
        params: this.$httpCustApp.adornParams({
          page: this.page,
          limit: this.limit,
          orgId: this.dataForm.orgId,
          createUserMobile: this.dataForm.mobile,
          placeType: this.dataForm.placeType,
          buildingId: this.dataForm.buildingId,
          houseId: this.dataForm.houseId
        })
      }).then(({data}) => {
        this.$toast.clear()
        if (data && data.code === 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.totalCount = data.page.totalCount
          data.page.list.forEach(item => {
            if (item.problemFiles && item.problemFiles.length > 0) {
              let url = item.problemFiles[0].filePath
              item.fileUrl = getImageStream(url)
            }
          })
          this.dataList = this.dataList.concat(data.page.list)
          this.loading = false;
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goInfo(item) {
      if (item.solveStatus != 30) {
        this.$router.push({path: '/problemInfo', query: {item}})
      } else {
        this.$router.push({path: '/dealInfo', query: {id: item.id}})
      }
    },
    onPullDownRefresh(e) {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },

    handling(id, status, item) {
      let data = {'id': id, 'status': status}
      this.$store.commit('setProblemData', data)
      this.$router.push({path: '/problem-handling', query: {ids: this.problemIds, orgId: item.community, type: item.problemType, typeStr: item.problemTypeStr}})
    },
    change(id, status, streetIsOpen) {
      let data = {'id': id, 'status': status, 'streetIsOpen': streetIsOpen}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-change')
    },
    deleteHandle(id) {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$httpCustApp({
          url: this.$httpCustApp.adornUrl('/wxapp/problem/delete'),
          method: 'post',
          params: this.$httpCustApp.adornParams({
            id: parseInt(id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
            })
            this.dataList = []
            this.page = 0
            this.getDataList()
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.type-check {
  // width: 750rpx;
  height: 420px;
  background: #FFFFFF;
  padding: 0 30px;
  padding-bottom: 24px;

  .title {
    height: 88px;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px 20px 0 0;
    font-size: 30px;
    font-weight: 600;
    color: #333333;
    line-height: 88px;
  }

  .type {
    display: flex;
    justify-content: space-between;


    font-size: 30px;
    font-weight: 500;

    .repair {
      width: 336px;
      height: 308px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }

      .text {
        width: 100%;
        position: absolute;
        bottom: 20px;
        // left: 108px;
        text-align: center;
      }
    }

    .live {
      color: #CB5828;
    }

    .public {
      color: #426CB6;
    }
  }
}

.list {
  margin-top: 24px;
  font-family: PingFangSC-Regular;
  .total {
    height: 96px;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 96px;
    background: #FFFFFF;
    padding: 0 30px;
    margin-bottom: 16px;
  }

  .items {
    background: #FFFFFF;
    padding: 0 30px;
    margin-bottom: 16px;

    .top {
      height: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .top-left {
        display: flex;
        align-items: center;

        .people {
          margin-right: 24px;
          font-size: 30px;
          font-weight: 600;
          color: #333333;
        }

        .time {
          font-size: 24px;
          font-weight: 400;
          color: #999999;
        }
      }

      .top-right {
        width: 112px;
        height: 52px;
        background: rgba(233, 86, 75, 0.1);
        border-radius: 8px;
        line-height: 52px;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #E9564B;
      }

      .wufa {
        background: rgba(102, 102, 102, 0.1);
        color: #666666;
      }

      .chulizhong {
        background: rgba(78, 124, 245, 0.12);
        color: #4E7CF5;
      }

      .yichuli {
        background: rgba(43, 143, 50, 0.1);
        color: #2B8F32;
      }

      .yipinlun {
        background: rgba(223, 137, 30, 0.1);
        color: #DF891E;
      }
    }

    .bottom {
      height: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        align-items: center;

        .picture {
          width: 160px;
          height: 160px;
          margin-right: 16px;

          ::v-deep .van-image__img {
            width: 160px;
            height: 160px;
          }
        }

        .detail {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          .detail-title {
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .detail-des {
            font-size: 30px;
            font-weight: 400;
            color: #666666;
          }
        }
      }

      .bottom-right {
        width: 16px;
        height: 28px;
        margin-left: 42px;
      }

    }

    .bottom-btn {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: end;
      padding: 8px 0;

      .item_btn {
        font-weight: bold;
        border: none;
        text-align: center;
        line-height: 64px;
        padding: 0 40px;
        height: 64px;
        font-size: 24px;
        border-radius: 32px;
        margin: 10px 0 0 20px;
      }
    }
  }
}
</style>
